import * as styleVars from '@common/styles/constants'
import * as yup from 'yup'

import Button, {
  ButtonDropdownOptions,
  ButtonProps,
} from '@common/components/button/v2'
import { FormProvider, useForm } from 'react-hook-form'
import React from 'react'

import Breadcrumb from '@common/components/breadcrumb'
import { Form } from '@common/components/form'
import SvgCheck from '@static/svg/icons/check-small.svg'
import SvgClose from '@static/svg/icons/close-small.svg'
import SvgEdit from '@static/svg/icons/edit.svg'
import { css } from '@emotion/react'
import dynamic from 'next/dynamic'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import { DashboardMainHeaderTitleLoader } from './loaders'
import { Tooltip } from '@common/components/tooltip/v2'
import { autoPlacement, flip, shift } from '@floating-ui/react'
import classNames from 'classnames'
const Spinner = dynamic(() => import('@common/components/spinner'), {
  ssr: false,
})

interface MainProps extends React.HTMLAttributes<HTMLElement> {
  className?: string | undefined
  overlapSidebar?: boolean | undefined
  backgroundIcon?: string | undefined
  backgroundIconSize?: string
}
interface MainContentProps extends React.HTMLAttributes<HTMLElement> {
  className?: string | undefined
}
export interface MainHeaderButton extends ButtonProps {
  className?: string
  text?: string
  hide?: boolean
  icon?: React.FC<React.PropsWithChildren<any>>
  onClick?: (e: any) => void
  dropdownOptions?: ButtonDropdownOptions[]
  tooltip?: {
    enable: boolean
    content: string
  }
  id?: string
  disabled?: boolean
  'data-cy'?: string
}
interface MainHeaderProps
  extends Omit<React.HTMLAttributes<HTMLElement>, 'title'> {
  className?: string
  wrapperCss?: any
  breadCrumbs?: string[]
  title?: string
  titleClassName?: string
  titleClassNameRest?: string
  customTitleRender?: (title: string) => JSX.Element
  hasBackButton?: boolean
  onClickBackButton?: () => void
  addHighlight?: boolean
  buttonBar?: MainHeaderButton[]
  loading?: boolean
  isEditable?: boolean
  onEditSubmit?: (val: string) => void
  placeholderOnEdit?: string
}

type MainContent = React.FC<React.PropsWithChildren<MainContentProps>>
type MainHeader = React.FC<React.PropsWithChildren<MainHeaderProps>>
interface Main extends React.FC<MainProps> {
  Content: MainContent
  Header: MainHeader
}

const schema = yup.object().shape({
  surveyName: yup.string().required('A survey name is required.'),
})

interface FormInterface {
  surveyName: string
}

const MainHeader: MainHeader = (props) => {
  const {
    children,
    className,
    title = '',
    hasBackButton,
    breadCrumbs,
    buttonBar = [],
    isEditable = false,
    wrapperCss,
    onClickBackButton,
    customTitleRender,
    onEditSubmit,
    loading = false,
    titleClassName = 'dashboard-title',
    titleClassNameRest,
    placeholderOnEdit = 'Edit Survey Name',
    ...rest
  } = props

  const classes = classNames('pq-main__header', className || '')
  const [isEditTitle, setIsEditTitle] = React.useState<boolean>(false)

  const methods = useForm<FormInterface>({
    values: {
      surveyName: title,
    },
    resolver: yupResolver(schema),
  })
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = methods

  const surveyName = watch('surveyName')

  const startEdittingTitle = () => {
    setIsEditTitle(true)
  }
  const stopEdittingTitle = () => setIsEditTitle(false)

  const [isSavingTitle, setIsSavingTitle] = React.useState(false)

  const handleSubmitEditSurveyName = async (data) => {
    if (onEditSubmit) {
      setIsSavingTitle(true)
      await onEditSubmit(data.surveyName)
      setIsSavingTitle(false)
      stopEdittingTitle()
    }
  }

  const renderButtonBar = (): JSX.Element[] | null => {
    if (!buttonBar.length) return null

    const buttons = buttonBar.map((btn, btnIndex) => {
      // eslint-disable-next-line no-shadow
      const {
        className: btnBarClassName = '',
        dropdownOptions = [],
        id = '',
        btnTheme = 'default',
        outlined = 'primary-blue',
        disabled = false,
        icon: BtnIcon,
        text,
        tooltip,
        hide,
        ...restBtn
      } = btn

      if (hide) return null

      const btnClasses = classNames({
        ['pq-mr-2']: !!text,
      })

      return (
        <Tooltip
          key={btnIndex}
          placement="top"
          enableClick={false}
          enableHover={tooltip?.enable}
          middleware={[
            autoPlacement({
              allowedPlacements: ['top'],
              alignment: 'start',
            }),
            flip(),
            shift(),
          ]}
          useArrow
        >
          <Tooltip.Trigger>
            <Button
              id={id}
              data-cy={`main-header-button-${btnIndex}`}
              disabled={btn?.isLoading || disabled}
              dropdownOptions={dropdownOptions}
              className={`${btnBarClassName} dashboard-header-btn`}
              btnTheme={btnTheme}
              outlined={outlined}
              // eslint-disable-next-line react/no-array-index-key
              key={btnIndex}
              {...restBtn}
            >
              {BtnIcon ? <BtnIcon className={btnClasses} /> : null}
              {text ? text : null}
            </Button>
          </Tooltip.Trigger>
          <Tooltip.Content>{tooltip?.content}</Tooltip.Content>
        </Tooltip>
      )
    })

    return buttons
  }

  const renderTitle = (): JSX.Element => {
    if (loading) {
      return (
        <h2 className="dashboard-title">
          <DashboardMainHeaderTitleLoader />
        </h2>
      )
    }

    if (isEditTitle) {
      return (
        <FormProvider {...methods}>
          <Form
            onSubmit={handleSubmit(handleSubmitEditSurveyName)}
            className="pq-d-inline-block"
            css={css`
              position: relative;
              top: -3px;
              height: 31px;
            `}
          >
            <div
              css={css`
                display: inline-flex;
                justify-content: center;
                align-items: baseline;
              `}
            >
              <Form.Input
                {...register('surveyName', {
                  required: true,
                })}
                pqInputBoardCss={css`
                  height: 100%;
                  max-width: 240px;
                  & .pq-input-board {
                    height: 100%;
                    & input {
                      height: 100% !important;
                      max-width: 240px;
                      padding-left: 8px !important;
                    }
                  }
                `}
                placeholder={placeholderOnEdit}
                errorMessage={errors.surveyName && errors.surveyName.message}
                errorBoard
                errorBoardCss={css`
                  text-align: center;
                `}
              />

              <div
                css={css`
                  display: flex;
                  position: relative;
                  top: 4px;
                  margin-top: 5px;
                `}
              >
                <Button
                  className="dashboard-title-cancel-btn"
                  btnTheme="secondary-blue"
                  type="button"
                  disabled={isSavingTitle}
                  onClick={() => {
                    stopEdittingTitle()
                  }}
                  innerBtnCss={css`
                    font-size: 16px !important;
                    margin: 0 5px;
                    padding: 4px !important;
                  `}
                >
                  <SvgClose
                    css={css`
                      & path:last-child {
                        fill: ${styleVars.primaryBlue};
                      }
                    `}
                  />
                </Button>

                <Button
                  className="dashboard-title-save-btn"
                  type="submit"
                  btnTheme="primary-blue"
                  disabled={isSavingTitle}
                  innerBtnCss={css`
                    font-size: 16px !important;
                    margin: 0 5px;
                    padding: 4px !important;
                  `}
                >
                  {isSavingTitle ? (
                    <Spinner widthAndHeight={14} color={styleVars.white} />
                  ) : (
                    <SvgCheck
                      css={css`
                        & path:last-child {
                          fill: ${styleVars.white};
                        }
                      `}
                    />
                  )}
                </Button>
              </div>
            </div>
          </Form>
        </FormProvider>
      )
    }

    if (customTitleRender) {
      return (
        <h2 className="dashboard-title">
          {customTitleRender(surveyName)}
          {isEditable && (
            <SvgEdit
              onClick={startEdittingTitle}
              className="pq-ml-1 pq-d-inline pq-hover-pointer dashboard-title-edit-btn"
            />
          )}
        </h2>
      )
    }

    return (
      <h2
        className={titleClassName}
        data-cy="dashboard-layout-title"
        css={
          titleClassNameRest &&
          css`
            font-size: 1.5rem;
          `
        }
      >
        {surveyName}{' '}
        {isEditable && (
          <SvgEdit
            onClick={startEdittingTitle}
            className="pq-ml-1 pq-d-inline pq-hover-pointer dashboard-title-edit-btn"
          />
        )}
      </h2>
    )
  }

  return (
    <div className={classes} {...rest} css={wrapperCss}>
      <div className="pq-row">
        <div className="pq-col">
          {Array.isArray(breadCrumbs) && breadCrumbs.length > 0 && (
            <Breadcrumb items={breadCrumbs} />
          )}
          <div
            className="pq-main__header_title"
            data-cy="dashboard-layout-back-btn"
          >
            {hasBackButton && (
              <Button
                btnTheme="unstyled"
                type="button"
                onClick={() => {
                  if (onClickBackButton) {
                    return onClickBackButton()
                  }

                  window.history.back()
                }}
                css={css`
                  padding: 0 !important;
                  margin-top: 9px;
                  :hover {
                    opacity: 0.5;
                  }
                `}
              >
                <img
                  src="/static/svg/icons/back.svg"
                  alt="back"
                  css={css`
                    height: 100%;
                    width: 18px;
                  `}
                />
              </Button>
            )}

            <div data-cy="dashboard-title">{renderTitle()}</div>
          </div>
        </div>

        <div
          data-cy="pq-main__header_button_bar"
          className="pq-col pq-main__header_button_bar"
          css={css({
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
          })}
        >
          {renderButtonBar()}
          {children}
        </div>
      </div>
    </div>
  )
}

const MainContent: MainContent = (props) => {
  const { children, className, ...rest } = props

  const classes = classNames('pq-main__content', className || '')

  return (
    <MainContentWrapper className={classes} {...rest}>
      {children}
    </MainContentWrapper>
  )
}

const Main: Main = (props) => {
  const { children, className, ...rest } = props

  const classes = classNames('pq-main', className || '')

  return (
    <MainWrapper className={classes} {...rest}>
      <div className="pq-main-inner">{children}</div>
    </MainWrapper>
  )
}

Main.Content = MainContent
Main.Header = MainHeader

export { Main }

const MainContentWrapper = styled.div`
  padding-bottom: 120px;
`

const MainWrapper = styled.div`
  &.pq-main {
    grid-area: main;

    &.pq-analytics {
      height: 100%;
      min-height: calc(100vh - 70px);
    }

    .pq-main__header {
      margin: 1.25rem 0;

      .pq-main__header_title {
        display: flex;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: normal;
        color: #0d1331;
        align-items: center;

        img {
          margin-right: 8px;
        }
      }
    }
  }

  .dashboard-title-save-btn,
  .dashboard-title-cancel-btn {
    display: inline-flex;
    align-items: center;
  }

  .pq-main-inner {
    width: 100%;
    height: 100%;
    ${(props: MainProps) =>
      props.overlapSidebar
        ? css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -32%;
          `
        : ''}
  }

  ${(props: MainProps) =>
    props.backgroundIcon === 'welcome-full'
      ? css`
          @media (min-width: ${styleVars.breakPointMd}) {
            background-image: url(/static/images/welcome-full.svg);
          }

          background-repeat: no-repeat;
          background-position: 100% 0;
        `
      : ''}

  ${(props: MainProps) =>
    props.backgroundIcon === 'welcome'
      ? css`
          background-image: url(/static/images/welcome.svg);
          background-repeat: no-repeat;
          background-position: top right;
        `
      : ''}

    ${(props: MainProps) =>
    props.backgroundIcon === 'builder'
      ? css`
          background-image: url(/static/images/builder.svg);
          background-repeat: no-repeat;
          background-position: top right;
        `
      : ''}

    ${(props: MainProps) =>
    props.backgroundIconSize
      ? css`
          background-size: ${props.backgroundIconSize};
        `
      : ''}

    ${(props: MainProps) =>
    props.backgroundIcon
      ? css`
          margin-top: ${props.backgroundIcon === 'welcome-full'
            ? '-72px'
            : '-2px'};
        `
      : ''}
`
